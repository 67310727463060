import { defaultImage } from "./default_image";
import { DefaultImage } from "./images_for_vender";

export interface CheckWithPrefixResponse {
  prefix: string;
  domain: string;
  isPromotion: boolean;
  isDealer: boolean;
  isLottohot: boolean;
  isufabet: boolean;
  lineidchat?: string | null;
  priority_prefix_hight_level?: string | null;
  disableRegister: boolean;
  defaultImage: DefaultImage;
  disable_web_site: boolean;
  isChatWebsite: boolean;
}

interface CheckWithPrefixProps {
  host: string;
}

export const PREFIX_UFABER1 = "uf1";
export const PREFIX_UFABETAM = "ufa";
export const PREFIX_SNAMBET = "ufs";
export const PREFIX_SNAMBET_HIGHT = "snb";
export const PREFIX_UFAKUB555 = "uk5";
export const PREFIX_UFAGAME = "ufg";
export const PREFIX_UFAONLINE1 = "ufo";
export const PREFIX_UFABETAG = "ufb";
export const PREFIX_UFCASINO7 = "ufc";
export const PREFIX_UF9Pro = "ufp";
export const PREFIX_UFAMM = "ufm";
export const PREFIX_UFAFUNVIP = "uvp";

export function CheckWithPrefix(
  props: CheckWithPrefixProps
): CheckWithPrefixResponse {
  switch (props.host) {
    case "localhost:5001":
    case "10.148.0.4:5004":
    case "192.168.1.111:5001":
    case PREFIX_SNAMBET:
    case "ufabet.snambet.com":
      return {
        prefix: PREFIX_SNAMBET,
        domain: "ufabet.snambet.com",
        isPromotion: false,
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        priority_prefix_hight_level: PREFIX_SNAMBET_HIGHT,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,

        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5002":
    case PREFIX_UFABER1:
    case "ufaber1.com":
    case "ufaber1.net":
    case "ufaber1.vip":
      return {
        prefix: PREFIX_UFABER1,
        domain: "ufaber1.com",
        isPromotion: false,
        lineidchat: "https://line.me/R/ti/p/@378fbilz",
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: true,
        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5003":
    case PREFIX_UFABETAM:
    case "ufabet.am":
    case "ufabetam.bet":
    case "ufabetam.com":
      return {
        prefix: PREFIX_UFABETAM,
        domain: "ufabet.am",
        isPromotion: false,
        lineidchat: "https://lin.ee/adoSOah",
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: true,
        defaultImage: defaultImage(),
      };

    case "10.148.0.4:5005":
    case PREFIX_UFAKUB555:
    case "ufakub555.com":
      return {
        prefix: PREFIX_UFAKUB555,
        domain: "ufakub555.com",
        isPromotion: true,
        lineidchat: "https://line.me/R/ti/p/@550botys",
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5006":
    case PREFIX_UFAGAME:
    case "ufagame.biz":
      return {
        prefix: PREFIX_UFAGAME,
        domain: "ufagame.biz",
        isPromotion: true,
        lineidchat: "https://line.me/R/ti/p/@149lkoyu",
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5007":
    case PREFIX_UFAONLINE1:
    case "ufaonline1.com":
      return {
        prefix: PREFIX_UFAONLINE1,
        domain: "ufaonline1.com",
        isPromotion: true,
        lineidchat: "https://lin.ee/wEUUWQq",
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5008":
    case PREFIX_UFABETAG:
    case "ufabet.ag":
      return {
        prefix: PREFIX_UFABETAG,
        domain: "ufabet.ag",
        isPromotion: false,
        lineidchat: "https://lin.ee/4y0tmWN",
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: true,
        disable_web_site: true,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5009":
    case PREFIX_UFCASINO7:
    case "ufcasino7.com":
      return {
        prefix: PREFIX_UFCASINO7,
        domain: "ufcasino7.com",
        isPromotion: false,
        lineidchat: "https://line.me/R/ti/p/@753mwcfu#~",
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5010":
    case PREFIX_UF9Pro:
    case "uf9pro.com":
      return {
        prefix: PREFIX_UF9Pro,
        domain: "uf9pro.com",
        isPromotion: false,
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };
    case "10.148.0.4:5011":
    case PREFIX_UFAMM:
    case "ufamm.com":
    case "www.ufamm.com":
      return {
        prefix: PREFIX_UFAMM,
        domain: "ufamm.com",
        isPromotion: false,
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };

    case PREFIX_UFAFUNVIP:
    case "ufafun.vip":
      return {
        prefix: PREFIX_UFAFUNVIP,
        domain: "ufafun.vip",
        isPromotion: false,
        isDealer: true,
        isLottohot: true,
        isufabet: true,
        disableRegister: false,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };

    default:
      return {
        prefix: "",
        domain: "",
        isPromotion: false,
        isDealer: false,
        isLottohot: false,
        isufabet: false,
        disableRegister: true,
        disable_web_site: false,
        isChatWebsite: false,
        defaultImage: defaultImage(),
      };
  }
}

export function GetPrefixLevel(info: CheckWithPrefixResponse): string {
  const prefix =
    info.priority_prefix_hight_level == null
      ? info.prefix
      : info.priority_prefix_hight_level;
  return prefix;
}
